<template>
<div id="scope" class="scope">
    <b-container class="bv-example-row">
        <b-row class="mb-5">
             <b-col sm="12" class="ml-img">
                <p>บริษัท เกาอักษร จำกัด</p>

                <p>ที่อยู่ 279/97 ถนน สกุลดี แขวง หนองจอก เขต หนองจอก กรุงเทพฯ</p>
             </b-col>
        </b-row>
        <b-row v-for="(info,index) in infomation" :key="index" class="mb-5">
            <b-col sm="3" class="img">
                <img :src="require(`@/assets/${info.src}`)" />
            </b-col>
            <b-col sm="9" class="info pt-2" v-if="index%2 == 0">
                <a :href="info.href" v-if="info.href.length > 0"><span class="infoText">{{info.desc}}</span></a>
                <a v-else @click="goToOtherPage()"><span class="infoText">{{info.desc}}</span></a>
            </b-col>
            <b-col sm="9" class="info pt-2" v-else>
                <a :href="info.href1"><span class="infoText">{{info.desc1}}</span></a> / <a :href="info.href2"><span class="infoText">{{info.desc2}}</span></a>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
export default {
    mounted() {
        //document.title = 'ติดต่อเรา';
        this.checkMode()
    },
    data() {
        return {
            infomation: [{
                    src: 'facebook3.png',
                    desc: 'https://www.facebook.com/NovelRealm',
                    href: ''
                },
                {
                    src: 'envelope.png',
                    desc1: 'manager@novelrealm.com ',
                    desc2: 'tossapon@novelrealm.com',
                    href1: 'mailto:manager@novelrealm.com',
                    href2: 'tossapon@novelrealm.com'
                }
            ]
        }
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    methods: {
        goToOtherPage() {
            window.open("https://www.facebook.com/NovelRealm", '_blank')
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('scope').classList.remove('darkmode')
            document.getElementsByClassName('infoText').forEach(element => {
                element.classList.remove('darkmodeText')
            });
        },
        darkmode() {
            document.getElementById('scope').classList.add('darkmode')
            document.getElementsByClassName('infoText').forEach(element => {
                element.classList.add('darkmodeText')
            });
        }
    }
}
</script>

<style scoped>
.scope {
    background-color: #ffffff;
    padding: 6% 0% 4% 10%;
    margin: 3% 10% 3% 10%;
}

img {
    width: 36px;
}

.img {
    text-align: right;
}

.info {
    font-size: 16px;
}

.infoText {
    color: #505050;
}

.ml-img{
    margin-left: calc(22% - 36px);
    padding-left: 0px;
}

@media screen and (max-width: 500px) {
    .scope {
        padding: 10% 0% 1% 0%;
        margin: 2% 2% 0.5% 2%;
    }

    img {
        width: 30px;
    }

    .img,
    .info {
        text-align: center;
    }

    .info {
        font-size: 13px;
    }
}

@media screen and (min-width: 1441px) {
    .scope {
        margin: 3% 20% 3% 20%;
    }
}

.darkmode {
    background-color: #35363A;
}

.darkmodeText{
    color: #ffffff;
}
</style>
